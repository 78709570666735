dmx.Attribute('animate-inview', 'mounted', function (node, attr) {
  node.inview = {
    effect: attr.value,
    delay: attr.modifiers.delay,
    duration: attr.modifiers.duration,
    ratio: (parseInt(attr.argument, 10) || 100) / 100,
  };

  dmx.animate.observer.observe(node);
});
